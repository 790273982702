import React, { FunctionComponent, useRef, ChangeEvent } from "react";
import classnames from "classnames";
import ToolTip from "./Tooltip";
import type { Fiddle } from "../types";

import styles from "./Origins.module.css";

import { VCL_ORIGIN_PATTERN, NUM_VCL_ORIGINS, MAX_ORIGIN_LEN } from "../constants";

type Props = {
  changeFiddle: (updater: (fiddle: Fiddle) => Fiddle) => void;
  onValidation: (isAllValid: boolean) => void;
  origins: string[];
  readOnly: boolean;
};

const validate = (origins: string[]): boolean[] => {
  return [...Array(NUM_VCL_ORIGINS)].map(
    (_, idx) => (idx !== 0 && !origins[idx]) || (VCL_ORIGIN_PATTERN.test(origins[idx]) && origins[idx].length < MAX_ORIGIN_LEN)
  );
};

const Origins: FunctionComponent<Props> = (props: Props) => {
  const domEls = useRef([...Array(NUM_VCL_ORIGINS)]);

  const editOrigin = (idx: number, event: ChangeEvent<HTMLInputElement>): void => {
    const origins = [...props.origins];
    const newval = event.target.value;
    if (!newval) {
      origins.splice(idx, 1);
    } else {
      origins[idx] = newval;
    }
    props.changeFiddle((fiddle) => ({ ...fiddle, origins }));
    props.onValidation(validate(origins).every((x) => x) && origins.length > 0);
  };

  const validation = validate(props.origins);

  return (
    <div>
      <h2>Origin servers</h2>
      <ol className={styles.origins}>
        {[...Array(NUM_VCL_ORIGINS)]
          .filter((_, i) => !props.readOnly || props.origins[i])
          .map((_, idx) => {
            const classes = classnames({
              [styles.active]: props.origins[idx] || props.origins[idx - 1] || idx === 0,
              invalid: !validation[idx],
            });
            const id = `origin-${idx}`;

            return (
              <li
                key={id}
                className={classes}
                ref={(el): void => {
                  domEls.current[idx] = el;
                }}
              >
                <label htmlFor={id}>{idx}</label>
                <input
                  id={id}
                  type="text"
                  readOnly={props.readOnly}
                  value={props.origins[idx] || ""}
                  onChange={(evt): void => editOrigin(idx, evt)}
                />
                {!validation[idx] && (
                  <ToolTip placement="right" type="validation" target={domEls.current[idx]} trigger="manual" visible>
                    Origin must be a valid URL (max {MAX_ORIGIN_LEN} chars)
                  </ToolTip>
                )}
              </li>
            );
          })}
      </ol>
    </div>
  );
};

export default Origins;
