import { Span, SpanStatusCode } from "@opentelemetry/api";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { Resource } from "@opentelemetry/resources";
import { SimpleSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { FetchError } from "@opentelemetry/instrumentation-fetch/build/src/types";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import ReactGA from "react-ga";
import { UnavoidableAny } from "../types";

const OTEL_COLLECTOR_URL = process.env.REACT_APP_OTEL_HTTP_COLLECTOR_BASE_URL || "/telemetry";

const resource = new Resource({
  [SemanticResourceAttributes.SERVICE_NAME]: "Fiddle Frontend",
  "commit_sha": process.env.REACT_APP_COMMIT_SHA,
  "__tenant": "devrel"
});
const provider = new WebTracerProvider({ resource });
const exporter = new OTLPTraceExporter({
  url: OTEL_COLLECTOR_URL + "/v1/traces",
});

provider.addSpanProcessor(new SimpleSpanProcessor(exporter));
provider.register({ contextManager: new ZoneContextManager() });

registerInstrumentations({
  instrumentations: [
    new FetchInstrumentation({
      propagateTraceHeaderCorsUrls: /.*/g,
      clearTimingResources: true,
      applyCustomAttributesOnSpan: (span: Span, request: Request | RequestInit, result: Response | FetchError) => {
        const attributes = (span as UnavoidableAny).attributes; // OTel Span type does not list .attributes prop
        if (attributes.component === "fetch") {
          span.updateName(`${attributes["http.method"]} ${attributes["http.url"]}`);
        }
        if (result.status && result.status > 299) {
          span.setStatus({ code: SpanStatusCode.ERROR });
        }
      },
    }),
  ],
});

/* Google Analytics */

let gaDebug = false;
try {
  gaDebug = Boolean(window.localStorage.getItem("debug-ga"));
} catch (ex) {
  console.warn("Unable to use localStorage");
}
ReactGA.initialize("UA-25770359-22", {
  debug: gaDebug,
  gaOptions: {
    storage: "none",
    storeGac: false,
  },
});
if (!gaDebug && process.env.NODE_ENV === "development" && window.location === window.parent.location) {
  console.info("To enable debugging for Google Analytics, set debug-ga to true in localStorage");
}
