type FiddleRecord = {
  created: boolean;
  lastAccessed: Date;
  lastSaved: Date | null;
  lastExecuted: Date | null;
};

// Store passwords indefinitely, but keep a limited history of fiddle access
const KEYS = {
  passwords: "fiddles",
  history: "fiddles-history",
};
const HISTORY_LEN = 60;

let passwords: Record<string, string>;
let history: Map<string, FiddleRecord>;
let lsAvailable = false;

const readData = () => {
  try {
    const lsGet = (key: string) => JSON.parse(window.localStorage.getItem(key) || "null");
    passwords = lsGet(KEYS.passwords) || {};
    history = new Map(
      Object.entries(lsGet(KEYS.history) || []).map(([key, props]) => {
        const candRec = props as FiddleRecord;
        return [
          key,
          {
            lastAccessed: new Date(candRec.lastAccessed),
            lastExecuted: candRec.lastExecuted ? new Date(candRec.lastExecuted) : null,
            lastSaved: candRec.lastSaved ? new Date(candRec.lastSaved) : null,
            created: "created" in candRec ? Boolean(candRec.created) : false,
          },
        ];
      })
    );
    lsAvailable = true;
  } catch (e) {
    console.warn(e);
    console.info("Storage not available, no fiddle history or passwords will be stored");
  }
};

export const isAvailable = (): boolean => lsAvailable;

export const getPassword = (id: string): string | undefined => passwords[id] || undefined;

export const setPassword = (id: string, password: string): void => {
  if (!lsAvailable) throw new Error("Unable to set a password: localStorage is inaccessible on this device");
  readData();
  passwords[id] = password;
  window.localStorage.setItem(KEYS.passwords, JSON.stringify(passwords));
};

export const removePassword = (id: string): void => {
  if (!lsAvailable) throw new Error("Unable to remove a password: localStorage is inaccessible on this device");
  readData();
  delete passwords[id];
  window.localStorage.setItem(KEYS.passwords, JSON.stringify(passwords));
};

export const getHistory = (): Map<string, FiddleRecord> => history;

export const recordCreation = (id: string): void => {
  readData();
  history.set(id, {
    lastAccessed: new Date(),
    lastSaved: new Date(),
    lastExecuted: null,
    created: true,
  });
  trimAndSaveHistory();
};

export const recordExecution = (id: string): void => {
  readData();
  history.set(id, {
    ...(history.get(id) || { created: false, lastSaved: null }),
    lastExecuted: new Date(),
    lastAccessed: new Date(),
  });
  trimAndSaveHistory();
};

export const recordAccess = (id: string): void => {
  readData();
  history.set(id, {
    ...(history.get(id) || { created: false, lastExecuted: null, lastSaved: null }),
    lastAccessed: new Date(),
  });
  trimAndSaveHistory();
};

export const recordSave = (id: string): void => {
  readData();
  history.set(id, {
    ...(history.get(id) || { created: false, lastExecuted: null }),
    lastAccessed: new Date(),
    lastSaved: new Date(),
  });
  trimAndSaveHistory();
};

const trimAndSaveHistory = () => {
  while (history.size > HISTORY_LEN) {
    history.delete(history.keys().next().value);
  }
  window.localStorage.setItem(KEYS.history, JSON.stringify(Object.fromEntries(history)));
};

readData();
