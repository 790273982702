import React, { FunctionComponent, useState, useRef, useEffect, useCallback } from "react";
import Icon from "./Icon";
import * as dataYaml from "../lib/dataYaml";
import classnames from "classnames";

import { HTTP_METHODS, REQURL_PATTERN, HTTP_HEADER_PATTERN, REQUEST_DELAY_MIN, REQUEST_DELAY_MAX } from "../constants";
import PickerControl from "./PickerControl";
import ToolTip from "./Tooltip";
import type { Fiddle, FiddleLanguage, RequestDataType, RequestType } from "../types";

import styles from "./RequestEditor.module.css";

const DISALLOWED_HEADER_PATTERN = /^(fastly-fiddle-.*)$/;

type Props = {
  type: FiddleLanguage;
  isLegacyCompute: boolean;
  requests: RequestType[];
  readOnly: boolean;
  onChangeFiddleIntent: (updater: (fiddle: Fiddle) => Fiddle) => void;
  onValidationChange: (isValid: boolean) => void;
};

type RequestProp = string | boolean | RequestDataType | number | undefined;

const validate = (fieldFormat: string, candidate: string): true | string | JSX.Element => {
  if (fieldFormat === "headers") {
    return !candidate ||
      candidate
        .trim()
        .split(/\s*\n\s*/)
        .every((line) => {
          const patMatch = line.match(HTTP_HEADER_PATTERN);
          return !line.trim() || (patMatch && !DISALLOWED_HEADER_PATTERN.test(patMatch[1]));
        })
      ? true
      : "Invalid or disallowed headers";
  } else if (fieldFormat === "path") {
    return REQURL_PATTERN.test(candidate) ? (
      true
    ) : (
      <span>
        Invalid request path (hint: check that it starts with <code>/</code> and contains no spaces
      </span>
    );
  } else if (fieldFormat === "data") {
    try {
      dataYaml.parse(candidate);
      return true;
    } catch (e) {
      return e.message;
    }
  } else {
    return true;
  }
};

const lineCount = (str: string): number =>
  (str || "")
    .trim()
    .split(/\s*\n\s*/)
    .filter((x) => !!x).length;

const RequestEditor: FunctionComponent<Props> = (props: Props) => {
  const [editableVals, setEditableVals] = useState<Record<string, RequestProp>>({});
  const [menuVisible, setMenuVisible] = useState<number>();
  const els = useRef<Record<string, HTMLElement>>({});

  const inputHandler = useCallback(
    (idx: number, field: string, newValue: RequestProp): void => {
      setEditableVals((prev) => {
        return { ...prev, [field + idx]: newValue };
      });
    },
    [props]
  );

  const saveHandler = useCallback(
    (idx: number, field: keyof RequestType, newValue?: RequestProp): void => {
      if (newValue !== undefined) {
        inputHandler(idx, field, newValue);
      } else {
        newValue = editableVals[field + idx];
        if (field === "data") {
          newValue = dataYaml.parse(String(newValue)) || {};
        }
      }
      const newRequests = props.requests.map((req, i) => (idx === i ? { ...req, [field]: newValue } : req));
      if (newValue !== props.requests[idx][field]) {
        props.onChangeFiddleIntent((fiddle) => ({ ...fiddle, requests: newRequests }));
      }
      setEditableVals((prev) => {
        delete prev[field + idx];
        return { ...prev };
      });
    },
    [editableVals, inputHandler, props]
  );

  const toggleMenu = (idx?: number): void => {
    requestAnimationFrame(() => setMenuVisible(idx));
  };

  const addRequest = (copyFromIdx: number): void => {
    props.onChangeFiddleIntent((fiddle) => ({ ...fiddle, requests: props.requests.concat({ ...props.requests[copyFromIdx] }) }));
  };

  const deleteRequest = (idxToDelete: number): void => {
    if (props.requests.length > 1) {
      props.onChangeFiddleIntent((fiddle) => ({
        ...fiddle,
        requests: props.requests.filter((req, idx) => idx !== idxToDelete), // TODO: splice?
      }));
    }
  };

  const constrainDelay = (delay: number): number => (delay < REQUEST_DELAY_MIN || delay > REQUEST_DELAY_MAX ? REQUEST_DELAY_MAX : delay);

  useEffect(() => {
    if (!Object.keys(editableVals).length) {
      props.onValidationChange(
        props.requests.map((r) => validate("headers", r.headers) === true && validate("path", r.path) === true).every((x) => x)
      );
    } else {
      props.onValidationChange(false);
    }
  }, [editableVals, props]);

  return (
    <div className={classnames(styles.requests, { [styles.multi]: props.requests.length > 1 })}>
      {props.requests.map((req: RequestType, idx) => {
        const headerCount = lineCount(req.headers);
        const headerValDesc = headerCount === 0 ? "Headers" : headerCount + " header" + (headerCount > 1 ? "s" : "");
        const testCount = lineCount(req.tests);
        const testsValDesc = testCount === 0 ? "Tests" : testCount + " test" + (testCount > 1 ? "s" : "");
        const flags = [];
        if (req.enableCluster && props.type === "vcl") flags.push("cluster");
        if (req.enableShield && props.type === "vcl") flags.push("shield");
        if (req.followRedirects) flags.push("follow");
        if (req.useFreshCache) flags.push("fresh cache");
        if (req.connType !== "h2") flags.push(req.connType);
        if (req.sourceIP && req.sourceIP !== "client") flags.push("IP override");
        if (req.delay) flags.push(`${req.delay}s delay`);
        const flagsDesc = flags.length > 2 ? flags.length + " options" : flags.length ? "Options: " + flags.join(", ") : "Options";
        const validation = {
          path: validate("path", req.path),
          headers: validate("headers", String(editableVals["headers" + idx] || "")),
          data: validate("data", String(editableVals["data" + idx] || "")),
        };
        return (
          <div className={styles.requestFieldGroup} key={idx}>
            <div className={styles.requestPathField}>
              <span className={styles.idx}>{idx + 1}.</span>
              <input
                title="Request path"
                value={req.path}
                readOnly={props.readOnly}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => saveHandler(idx, "path", evt.target.value)}
                ref={(el): void => {
                  if (el) els.current[idx + "path"] = el;
                }}
              />
              {!props.readOnly && (
                <span
                  className={styles.requestMenu}
                  ref={(el): void => {
                    if (el) els.current[idx + "menu"] = el;
                  }}
                >
                  <Icon type="menu" onClick={(): void => toggleMenu(idx)} />
                  <ToolTip
                    visible={menuVisible === idx}
                    onHideIntent={(): void => toggleMenu()}
                    placement="bottom"
                    trigger="manual"
                    target={els.current[idx + "menu"]}
                  >
                    <ul className="pick-list">
                      {props.requests.length > 1 && (
                        <li>
                          <label
                            onClick={(): void => {
                              toggleMenu();
                              deleteRequest(idx);
                            }}
                          >
                            Delete
                          </label>
                        </li>
                      )}
                      <li>
                        <label
                          onClick={(): void => {
                            toggleMenu();
                            addRequest(idx);
                          }}
                        >
                          Add another
                        </label>
                      </li>
                    </ul>
                  </ToolTip>
                </span>
              )}
              <ToolTip
                placement="bottom"
                type="validation"
                trigger="manual"
                target={els.current[idx + "path"]}
                visible={validation.path !== true}
              >
                {validation.path}
              </ToolTip>
            </div>
            <ul className={styles.requestOptions}>
              <li>
                <PickerControl value={req.method} defaultValue="">
                  <ul className="pick-list">
                    {HTTP_METHODS.map((verb) => (
                      <li key={verb}>
                        <label>
                          <input
                            type="radio"
                            value={verb}
                            disabled={props.readOnly}
                            checked={req.method === verb}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => saveHandler(idx, "method", evt.target.value)}
                          />
                          {verb}
                        </label>
                      </li>
                    ))}
                  </ul>
                </PickerControl>
              </li>
              <li>
                <PickerControl
                  value={headerValDesc}
                  defaultValue="Headers"
                  onOpen={(): void => inputHandler(idx, "headers", req.headers)}
                  onClose={(): void => saveHandler(idx, "headers")}
                >
                  <div>
                    <textarea
                      className={styles.requestTextarea}
                      readOnly={props.readOnly}
                      placeholder={"Example:\nCookie: Auth=12345...\nAccept-Language: en"}
                      value={String(editableVals["headers" + idx] || "")}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>): void => inputHandler(idx, "headers", evt.target.value)}
                      ref={(el): void => {
                        if (el) els.current[idx + "headers"] = el;
                      }}
                    />
                    <ToolTip
                      placement="bottom"
                      type="validation"
                      trigger="manual"
                      target={els.current[idx + "headers"]}
                      visible={validation.headers !== true}
                    >
                      {validation.headers}
                    </ToolTip>
                    <p className="note">
                      If you do not specify a <code>User-Agent</code> header here, the one from the current browser will be used
                    </p>
                  </div>
                </PickerControl>
              </li>
              <li>
                <PickerControl
                  value={req.body ? "Body populated" : "Body"}
                  defaultValue="Body"
                  onOpen={(): void => inputHandler(idx, "body", req.body)}
                  onClose={(): void => saveHandler(idx, "body")}
                >
                  <div>
                    <textarea
                      className={styles.requestTextarea}
                      readOnly={props.readOnly}
                      placeholder={"Example:\nfoo=123&amp;bar=456&amp;..."}
                      value={String(editableVals["body" + idx] || "")}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>): void => inputHandler(idx, "body", evt.target.value)}
                    />
                    <p className="note">Request body must be under 5K in size and is limited to UTF-8 encoding</p>
                  </div>
                </PickerControl>
              </li>
              <li>
                <PickerControl
                  value={flagsDesc}
                  defaultValue="Options"
                  onOpen={(): void => {
                    inputHandler(idx, "enableCluster", req.enableCluster);
                    inputHandler(idx, "enableShield", req.enableShield);
                    inputHandler(idx, "followRedirects", req.followRedirects);
                    inputHandler(idx, "useFreshCache", req.useFreshCache);
                    inputHandler(idx, "connType", req.connType);
                    inputHandler(idx, "sourceIP", req.sourceIP);
                    inputHandler(idx, "delay", req.delay);
                  }}
                  onClose={(): void => {
                    saveHandler(idx, "enableCluster");
                    saveHandler(idx, "enableShield");
                    saveHandler(idx, "followRedirects");
                    saveHandler(idx, "useFreshCache");
                    saveHandler(idx, "connType");
                    saveHandler(idx, "sourceIP");
                    saveHandler(idx, "delay");
                  }}
                >
                  <ul className="pick-list">
                    {props.type === "vcl" && (
                      <>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              disabled={props.readOnly}
                              checked={!!editableVals["enableCluster" + idx]}
                              onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
                                inputHandler(idx, "enableCluster", evt.target.checked)
                              }
                            />{" "}
                            Enable clustering
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              disabled={props.readOnly}
                              checked={!!editableVals["enableShield" + idx]}
                              onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
                                inputHandler(idx, "enableShield", evt.target.checked)
                              }
                            />{" "}
                            Enable shielding
                          </label>
                        </li>
                      </>
                    )}
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          disabled={props.readOnly}
                          checked={!!editableVals["followRedirects" + idx]}
                          onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
                            inputHandler(idx, "followRedirects", evt.target.checked)
                          }
                        />{" "}
                        Follow redirects
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          disabled={props.readOnly}
                          checked={!!editableVals["useFreshCache" + idx]}
                          onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
                            inputHandler(idx, "useFreshCache", evt.target.checked)
                          }
                        />{" "}
                        Use fresh cache
                      </label>
                    </li>
                    <li>
                      <label>
                        Connection:
                        <select
                          disabled={props.readOnly}
                          value={String(editableVals["connType" + idx])}
                          onChange={(evt: React.ChangeEvent<HTMLSelectElement>): void => inputHandler(idx, "connType", evt.target.value)}
                        >
                          <option value="http">Plain HTTP/1.1</option>
                          <option value="h1">HTTP/1.1 + TLS</option>
                          <option value="h2">HTTP/2</option>
                          {/* <option value='h3'>HTTP/3 over QUIC</option> */}
                        </select>
                      </label>
                    </li>
                    <li>
                      <label>
                        Client IP:
                        <select
                          disabled={props.readOnly}
                          value={String(editableVals["sourceIP" + idx])}
                          onChange={(evt: React.ChangeEvent<HTMLSelectElement>): void => inputHandler(idx, "sourceIP", evt.target.value)}
                        >
                          <option value="client">(Fiddle client)</option>
                          <option value="server">(Fiddle server)</option>
                          <option value="cn">China</option>
                          <option value="de">Germany</option>
                          <option value="jp">Japan</option>
                          <option value="ru">Russia</option>
                          <option value="br">Brazil</option>
                          <option value="za">South Africa</option>
                          <option value="uk">United Kingdom</option>
                          <option value="us">United States</option>
                        </select>
                      </label>
                    </li>
                    {(idx && (
                      <li>
                        <label>
                          Delay by:
                          <input
                            type="number"
                            min="0"
                            max="9"
                            placeholder="0"
                            className={styles.requestShortInput}
                            disabled={props.readOnly}
                            value={Number(editableVals["delay" + idx]) || ""}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
                              inputHandler(idx, "delay", constrainDelay(Number(evt.target.value)))
                            }
                          />{" "}
                          seconds{" "}
                        </label>
                      </li>
                    )) ||
                      null}
                  </ul>
                </PickerControl>
              </li>
              {props.type !== "vcl" && props.isLegacyCompute && (
                <li>
                  <PickerControl
                    value={Object.keys(req.data).length ? "Data populated" : "Data"}
                    defaultValue="Data"
                    onOpen={(): void => inputHandler(idx, "data", Object.keys(req.data).length ? dataYaml.stringify(req.data) : "")}
                    onClose={(): void => saveHandler(idx, "data")}
                  >
                    <div>
                      <textarea
                        className={styles.requestTextarea}
                        readOnly={props.readOnly}
                        placeholder={
                          'dictionaries:\n  config:\n    enabled: "true"\n    prefixLength: 5\n  redirects:\n    /resources: /newresources'
                        }
                        value={String(editableVals["data" + idx] || "")}
                        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>): void => inputHandler(idx, "data", evt.target.value)}
                        ref={(el): void => {
                          if (el) els.current[idx + "data"] = el;
                        }}
                      />
                      <ToolTip
                        placement="bottom"
                        type="validation"
                        trigger="manual"
                        target={els.current[idx + "data"]}
                        visible={validation.data !== true}
                      >
                        {validation.data}
                      </ToolTip>
                      <p className="note" style={{ textAlign: "right" }}>
                        <a target="_blank" rel="noreferrer" href="https://developer.fastly.com/learning/tools/fiddle/data/">Learn about specifying data sources</a>
                      </p>
                    </div>
                  </PickerControl>
                </li>
              )}
              <li>
                <PickerControl
                  value={testsValDesc}
                  defaultValue="Tests"
                  onOpen={(): void => inputHandler(idx, "tests", req.tests)}
                  onClose={(): void => saveHandler(idx, "tests")}
                >
                  <div>
                    <textarea
                      className={styles.requestTextarea}
                      readOnly={props.readOnly}
                      placeholder={"clientFetch.status is 200"}
                      value={String(editableVals["tests" + idx] || "")}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>): void => inputHandler(idx, "tests", evt.target.value)}
                    />
                    <p className="note" style={{ textAlign: "right" }}>
                      <a target="_blank" rel="noreferrer" href="https://developer.fastly.com/learning/tools/fiddle/testing/">Learn about writing tests</a>
                    </p>
                  </div>
                </PickerControl>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default RequestEditor;
