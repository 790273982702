import { DebouncedError } from "../errors";
import { AbortablePromise } from "../types";

export function formatDuration(durationUS: number): string {
  const millisec = 1000;
  const second = millisec * 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = Math.round((365 / 12) * day);
  const year = day * 365.25;
  return durationUS > 24 * month
    ? Math.round(durationUS / year) + " years"
    : durationUS > 3 * month
    ? Math.round(durationUS / month) + " months"
    : durationUS > 2 * day
    ? Math.round(durationUS / day) + " days"
    : durationUS > 2 * hour
    ? Math.round(durationUS / hour) + " hours"
    : durationUS > 2 * minute
    ? Math.round(durationUS / minute) + " min"
    : durationUS > 10 * second
    ? Math.round((durationUS / second) * 10) / 10 + " sec"
    : durationUS > millisec
    ? Math.round(durationUS / millisec) + " ms"
    : durationUS + " μs";
}

export function formatDataSize(bytes: number): string {
  const powers = ["b", "KB", "MB", "GB"];
  let unit = 0;
  while (bytes / Math.pow(1024, unit) > 500) unit++;
  return Math.round((bytes / Math.pow(1024, unit)) * 10) / 10 + powers[unit];
}

export function abortableWait(delay: number): AbortablePromise<void> {
  let timer: NodeJS.Timeout, rejectFn: (err: Error) => void;
  const prom: AbortablePromise<void> = Object.assign(
    new Promise<void>((resolve, reject) => {
      rejectFn = reject;
      timer = setTimeout(resolve, delay);
    }),
    {
      abort: () => {
        clearTimeout(timer);
        rejectFn(new DebouncedError());
      },
    }
  );
  return prom as AbortablePromise<void>;
}
