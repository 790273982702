import React, { useRef, useEffect, FunctionComponent } from "react";
import "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-rust";
import "prismjs/components/prism-go";
import "prismjs/components/prism-typescript";

import vclSyntax from "./languages/vcl";
import httpSyntax from "./languages/http";

import styles from "./CodeBlock.module.css";
import vclStyle from "./themes/vcl.module.css";
import httpStyle from "./themes/http.module.css";

import PrismNamespace, { Grammar } from "prismjs";
declare const Prism: typeof PrismNamespace;

Prism.languages.vcl = vclSyntax;
Prism.languages.http = httpSyntax;

const LANG_THEMES = {
  vcl: vclStyle.codeblocktheme,
  rust: vclStyle.codeblocktheme,
  javascript: vclStyle.codeblocktheme,
  go: vclStyle.codeblocktheme,
  http: httpStyle.codeblocktheme,
};

const GRAMMAR_MAPS: Record<string, Grammar> = {
  vcl: Prism.languages.vcl,
  http: Prism.languages.http,
  rust: Prism.languages.rust,
  javascript: Prism.languages.javascript,
  go: Prism.languages.go,
};

export function highlightText(code: string, language: string) {
  return Prism.highlight(code, GRAMMAR_MAPS[language], language);
}

type Props = {
  children: string;
  language: "vcl" | "http" | "rust" | "javascript" | "go";
  highlightAgainst?: string;
  withLineNumbers?: boolean;
  preHighlightedHtml?: string;
};

const CodeBlock: FunctionComponent<Props> = (props: Props) => {
  const contEl = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!contEl.current) return;
    if (!props.preHighlightedHtml) {
      Prism.highlightElement(contEl.current);
    }
    if (props.highlightAgainst) {
      const highlightSource = String(props.highlightAgainst).toLowerCase();
      contEl.current.querySelectorAll("span.header-name").forEach((el: Element) => {
        if (el instanceof HTMLSpanElement && highlightSource.includes(".http." + el.innerText.replace(":", "").toLowerCase())) {
          const lineEl = el.closest(".header-line");
          if (lineEl) lineEl.classList.add("interesting-line");
        }
      });
    }
  });

  const codeSource = props.children ?? "";
  const classes = [styles["code-block"], LANG_THEMES[props.language]];
  if (props.withLineNumbers) classes.push(styles["with-line-numbers"]);

  return (
    <pre className={classes.join(" ")}>
      <code
        ref={contEl}
        className={`language-${props.language}`}
        dangerouslySetInnerHTML={
          props.preHighlightedHtml != null
            ? {
                __html: props.preHighlightedHtml,
              }
            : undefined
        }
      >
        {props.preHighlightedHtml == null ? codeSource : undefined}
      </code>
      {props.withLineNumbers && (
        <span className={styles["line-numbers-rows"]}>
          {Array(codeSource.split("\n").length)
            .fill(0)
            .map((_, i) => (
              <span key={i} />
            ))}
        </span>
      )}
    </pre>
  );
};

export default CodeBlock;
