import React, { FunctionComponent } from "react";
import { Modal as ModalModule } from "react-overlays";
import classnames from "classnames";

import styles from "./Modal.module.css";

type Props = {
  visible: boolean;
  big?: boolean;
  onHideIntent: () => void;
  children: React.ReactNode;
};

const Modal: FunctionComponent<Props> = ({ visible, onHideIntent, big, children }: Props) => (
  <ModalModule
    containerClassName={classnames(styles.modal, { [styles.big]: big })}
    renderBackdrop={(bdProps): JSX.Element => <div {...bdProps} className={styles.backdrop} />}
    autoFocus
    show={visible}
    onHide={(): void => onHideIntent()}
  >
    <>{children}</>
  </ModalModule>
);

export default Modal;
