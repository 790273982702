import React, { FunctionComponent } from "react";
import type { Fiddle } from "../types";
import Icon from "./Icon";

import styles from "./RequestSummary.module.css";

type Props = {
  fiddle: Fiddle;
};

const RequestSummary: FunctionComponent<Props> = ({ fiddle }: Props) => (
  <div className={styles.container}>
    {fiddle.id && (
      <a className={`button ${styles.inlineClone}`} href={"/fiddle/" + fiddle.id + "/clone"} target="_blank" rel="noopener noreferrer">
        Clone
      </a>
    )}
    <h2>{fiddle.title}</h2>
    {fiddle.description && <p>{fiddle.description}</p>}
    <p>
      Press{" "}
      <span className={styles.mockButton}>
        <Icon type="play" />
        RUN
      </span>{" "}
      to test the code. This will create a Fastly service with the edge code shown in this example, and then make the following HTTP
      requests through it:
    </p>
    <ol className={styles.requestList}>
      {fiddle.requests.map((req, idx) => (
        <li key={idx}>
          <span className={styles.method}>{req.method}</span>
          <span className={styles.path}>{req.path}</span>
          <span className={styles.flags}>
            {Boolean(req.enableCluster) && <span className={styles.flag}>Cluster</span>}
            {Boolean(req.enableShield) && <span className={styles.flag}>Shield</span>}
            <span className={styles.flag}>{req.connType}</span>
          </span>
          {(req.headers || req.body || req.delay) && (
            <ul className={styles.requestProperties}>
              {req.delay && (
                <li key="delay">
                  delayed by: <code>{req.delay}</code>
                </li>
              )}
              {req.headers.split(/\s*\n\s*/).map((h) => (
                <li key={h}>
                  + header: <code>{h}</code>
                </li>
              ))}
              {req.body && (
                <li key="body">
                  + body: <code>{req.body}</code>
                </li>
              )}
            </ul>
          )}
        </li>
      ))}
    </ol>
  </div>
);

export default RequestSummary;
