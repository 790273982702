import React, { FunctionComponent } from "react";
import styles from "./Instructions.module.css";

const Instructions: FunctionComponent = () => (
  <div className={styles.instructions}>
    <p className={styles.launch}>
      <img src="/images/launch-rocket.svg" alt="launch-rocket" />
    </p>
    <p>
      <strong>Welcome!</strong>
    </p>
    <p>
      After typing a path in the box above, click RUN to send a request through Fastly, and see the effect of the configuration you have
      written. To find out more about all the cool things you can do with Fastly fiddle, read the{" "}
      <a href="https://developer.fastly.com/learning/tools/fiddle">documentation</a>.
    </p>
    <p>
      <strong>Please note:</strong>
    </p>
    <ul>
      <li>Any data entered into Fiddle can be viewed by anyone on the internet.</li>
      <li>
        Any code generated or provided here is "AS-IS" and should be tested and customized before incorporation into your production
        configuration
      </li>
      <li>
        Please see the <a href="http://www.fastlylabs.com/terms">terms of use</a> for more information.
      </li>
    </ul>
  </div>
);

export default Instructions;
