import React, { FunctionComponent, useState, useEffect } from "react";
import CopyToClipboardButton from "./CopyToClipboardButton";
import * as api from "../lib/api";
import type { Fiddle, InstallData } from "../types";
import usePrevious from "../lib/hooks/use-previous";

import styles from "./Install.module.css";

type Props = {
  fiddle: Fiddle;
};

// Patch the Navigator type to include the parts of NavigatorUAData that are used in Fiddle
// See https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData
type PatchedNavigator = Navigator & {
  userAgentData?: {
    platform: string;
  };
  platform?: string;
};

const Install: FunctionComponent<Props> = ({ fiddle }: Props) => {
  const [installData, setInstallData] = useState<InstallData>();
  const prevFiddle = usePrevious(fiddle);

  useEffect(() => {
    if (!prevFiddle || JSON.stringify(prevFiddle) !== JSON.stringify(fiddle)) {
      setInstallData(undefined);
      if (fiddle.id) {
        api.getInstallData(fiddle.id).then(setInstallData);
      }
    }
  }, [fiddle, prevFiddle]);

  const relevantBackends = Array(fiddle.origins.filter((x) => !!x).length)
    .fill(0)
    .map((_, i) => (fiddle.type === "vcl" ? "F_origin_" + i : "origin_" + i))
    .filter((token) => Object.values(fiddle.src).find((srcCode) => srcCode.includes(token)));

  const suggestedDirName = fiddle.title?.toLowerCase().replace(/[^\w]/g, "-") || "fastly-fiddle";
  const nav = navigator as PatchedNavigator;
  const platformChangeDir = (nav.userAgentData?.platform || nav.platform || "").toLowerCase().match(/win(dows|32)/) ? "chdir" : "cd";

  if (!installData) {
    return (
      <div className="install">
        <p style={{ textAlign: "center" }}>
          <img src="/images/progress.svg" className="progress-anim" alt="" /> Generating installation code...
        </p>
      </div>
    );
  }
  if (installData.installable === false) {
    return (
      <div className="install">
        <p style={{ textAlign: "center" }}>Sorry, this fiddle cannot be installed.</p>
      </div>
    );
  }

  return (
    <div className="install">
      <h2>Install this solution in your Fastly service</h2>
      {fiddle.type === "vcl" ? (
        <div className="split-modal">
          <div className="panel">
            <p>
              <strong>Install the VCL code to your service using the Fastly CLI:</strong>
            </p>
            <ol>
              <li>
                If you don't already have it, install the <a href="https://developer.fastly.com/reference/cli">Fastly CLI client</a>.
              </li>
              <li>
                Create a local working directory:
                <CopyToClipboardButton target="#cli-dir" />
                <br />
                <kbd id="cli-dir">
                  mkdir {installData.name} && {platformChangeDir} {installData.name}
                </kbd>
              </li>
              <li>
                Download the code:
                <CopyToClipboardButton target="#cli-download-src" />
                <br />
                <kbd id="cli-download-src">
                  curl -OLfsS "https://{window.location.hostname}/downloads/solution_{installData.name}-{installData.srcSig}-{fiddle.id}.
                  {"{" + Object.keys(installData.src || {}).join(",") + "}"}.snippet"
                </kbd>
              </li>
              <li>
                Set your Fastly service ID into your environment:
                <CopyToClipboardButton target="#cli-sid-env" />
                <br />
                <kbd id="cli-sid-env">export FASTLY_SERVICE_ID=[YOUR_SERVICE_ID]</kbd>
              </li>
              <li>
                Upload the code as VCL snippets to your service
                <CopyToClipboardButton target="#cli-upload-snip" />:<br />
                <kbd id="cli-upload-snip">
                  {Object.keys(installData.src || {}).map((srcName) => (
                    <span key={srcName}>
                      fastly vcl snippet create --name=solution_{installData.name}-{installData.srcSig}-{fiddle.id}.{srcName} --type=
                      {srcName} --content="$(cat solution_{installData.name}-{installData.srcSig}-{fiddle.id}.{srcName}.snippet)"
                      --version=latest --autoclone
                      <br />
                    </span>
                  ))}
                </kbd>
              </li>
              {relevantBackends.length > 0 && (
                <li>
                  Add the necessary backends
                  <CopyToClipboardButton target="#cli-create-backends" />
                  <br />
                  <small>
                    The source code explicitly refers to the following backends:{" "}
                    {relevantBackends
                      .map<React.ReactNode>((name) => <code key={name}>{name}</code>)
                      .reduce((prev, curr) => [prev, ", ", curr])}
                    . You could rename these in the code to match your existing backends if you have them, or create new backends with the
                    following commands
                  </small>
                  :<br />
                  <kbd id="cli-create-backends">
                    {relevantBackends.map((backendName, idx) => (
                      <span key={backendName}>
                        fastly backend create --name={backendName} --address={fiddle.origins[idx]} --version=latest
                        <br />
                      </span>
                    ))}
                  </kbd>
                </li>
              )}
              <li>
                Activate the new service version:
                <CopyToClipboardButton target="#cli-activate" />
                <br />
                <kbd id="cli-activate">fastly service-version activate --version=latest</kbd>
              </li>
            </ol>
            <div className={styles.warn}>
              This installer is currently unable to detect when fiddles use dictionaries, ACLs, and log endpoints. If the solution requires
              these, be sure to create them or your service may not work as you intend. The code generated here is "AS-IS" and should be
              tested and customized before incorporation into your production configuration
            </div>
          </div>
          <div className="panel">
            <p>
              <strong>Or: use the VCL in your own VCL file:</strong>
            </p>
            <p>
              Copy this into your VCL source, outside of any VCL subroutines: <CopyToClipboardButton target="#all-vcl" />
              <br />
              <kbd id="all-vcl" style={{ maxHeight: "300px", overflowY: "auto" }}>
                {installData.src?.init || ""}
              </kbd>
            </p>
            {Object.keys(installData.src || {}).length > 1 && (
              <p>
                Then call these custom subroutines from the main{" "}
                <a href="https://developer.fastly.com/learning/vcl/using#the-vcl-request-lifecycle">VCL lifecycle subroutines</a>.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="split-modal">
          <div className="panel">
            <p>
              <strong>Create a local Compute project from this fiddle:</strong>
            </p>
            <ol>
              <li>
                If you don't already have it, install the <a href="https://developer.fastly.com/reference/cli">Fastly CLI client</a>.
              </li>
              <li>
                Create a local working directory:
                <CopyToClipboardButton target="#cli-dir" />
                <br />
                <kbd id="cli-dir">
                  mkdir {suggestedDirName} && {platformChangeDir} {suggestedDirName}
                </kbd>
              </li>
              <li>
                Initialize a Compute project in the new directory:
                <CopyToClipboardButton target="#cli-init" />
                <br />
                <kbd id="cli-init">fastly compute init --from=https://fiddle.fastly.dev/fiddle/{fiddle.id}</kbd>
              </li>
              <li>Open the folder in your preferred IDE to edit the code.</li>
              <li>
                Publish the project to a new Fastly service:
                <CopyToClipboardButton target="#cli-publish" />
                <br />
                <kbd id="cli-publish">fastly compute publish</kbd>
              </li>
            </ol>
          </div>
          <div className="panel">
            <p>
              <strong>Or: Deploy this fiddle directly to a live Compute service:</strong>
            </p>
            <p>
              Follow the link below to launch <strong>Cloud Deploy</strong>, which will create a GitHub repo for you, set up continuous
              deployment using <a href="https://github.com/fastly/compute-actions">GitHub Actions</a>, and make your first deployment to
              your Fastly account:
            </p>
            <p>
              <a href={`https://deploy.edgecompute.app/fiddle/${fiddle.id}`} rel="noreferrer" target="_blank">
                <img src="https://deploy.edgecompute.app/button" alt="Deploy to fastly" />
              </a>
            </p>
            <p>
              Once deployed, you'll be able to edit your code using <a href="https://github.com/features/codespaces">GitHub Codespaces</a>{" "}
              or by cloning the repo to your local machine.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Install;
