import React, { FunctionComponent } from "react";
import styles from "./SystemErrors.module.css";

type Props = {
  list: Set<string>;
};

const SystemErrors: FunctionComponent<Props> = ({ list }: Props) =>
  list ? (
    <ul className={styles.systemErrors}>
      {Array.from(list).map((msg, idx) => (
        <li key={"err" + idx}>{msg}</li>
      ))}
    </ul>
  ) : null;

export default SystemErrors;
