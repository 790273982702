import OTel, { Attributes, Span, Context, SpanStatusCode } from "@opentelemetry/api";

type TracedCallback<T> = (span?: Span, context?: Context) => T;

export async function traceSpan<T>(tName: string, sName: string, attrs: Attributes, ctx: Context, callback: TracedCallback<T>): Promise<T>;
export async function traceSpan<T>(tName: string, sName: string, attrs: Attributes, callback: TracedCallback<T>): Promise<T>;
export async function traceSpan<T>(tName: string, sName: string, callback: TracedCallback<T>): Promise<T>;

export async function traceSpan<T>(
  tName: string,
  sName: string,
  arg3: Attributes | Context | TracedCallback<T>,
  arg4?: Context | TracedCallback<T>,
  arg5?: TracedCallback<T>
): Promise<T> {
  const attrs = arguments.length >= 4 ? (arg3 as Attributes) : {};
  const parentContext = arguments.length == 5 ? (arg4 as Context) : OTel.context.active();
  const callback = (arguments.length == 5 ? arg5 : arguments.length == 4 ? arg4 : arg3) as TracedCallback<T>;
  const tracer = OTel.trace.getTracer(tName);
  const handlesSpanEnd = callback.length;
  return tracer.startActiveSpan(sName, { attributes: attrs }, parentContext, async (span) => {
    const boundCallback = handlesSpanEnd ? callback.bind(null, span, OTel.context.active()) : callback;
    try {
      const result = await boundCallback();
      if (!handlesSpanEnd) span.end();
      return result;
    } catch (e) {
      span.recordException(e);
      span.setStatus({ code: SpanStatusCode.ERROR });
      span.end();
      throw e;
    }
  });
}

export const spanEvent = (name: string, attributes?: Attributes): void => {
  OTel.trace.getSpan(OTel.context.active())?.addEvent(name, attributes);
};
