import React, { useState, useRef, FunctionComponent } from "react";
import ToolTip from "./Tooltip";
import Icon from "./Icon";

const CONFIRM_TIP_DISPLAY_TIME = 2000;

type Props = {
  text?: string;
  target?: string;
};

const CopyToClipboardButton: FunctionComponent<Props> = ({ text, target }: Props) => {
  const [toastVisible, setToastVisible] = useState(false);
  const [toastTimer, setToastTimer] = useState<NodeJS.Timeout | null>();
  const buttonEl = useRef<HTMLElement>(null);

  const handleCopy = (): void => {
    if (!text && target) {
      const el = document.querySelector(target);
      if (el?.textContent) text = el.textContent;
    }
    if (!text) return;
    navigator.clipboard.writeText(text);
    setToastVisible(true);
    if (toastTimer) clearTimeout(toastTimer);
    setToastTimer(setTimeout(() => setToastVisible(false), CONFIRM_TIP_DISPLAY_TIME));
  };

  if (!("clipboard" in navigator)) return null;

  return (
    <span role="button" ref={buttonEl} className="button-copy" onClick={handleCopy}>
      <Icon type="copy" />
      {buttonEl.current && (
        <ToolTip placement="left" type="dark" trigger="manual" target={buttonEl.current} visible={toastVisible}>
          Copied to clipboard
        </ToolTip>
      )}
    </span>
  );
};

export default CopyToClipboardButton;
