import classNames from "classnames";
import React, { FunctionComponent, MouseEventHandler } from "react";
import styles from "./Icon.module.css";

type Props = {
  type: string;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler;
};

const Icon: FunctionComponent<Props> = ({ type, style, onClick }: Props) => (
  <i className={classNames(styles.icon, styles[type])} style={style} onClick={onClick} />
);

export default Icon;
