import React, { FunctionComponent, useState } from "react";

import styles from "./ManifestBanner.module.css";
import Icon from "./Icon";

type Props = Record<string, never>;

const ManifestBanner: FunctionComponent<Props> = () => {
  const [isHidden, setInternalHiddenState] = useState(localStorage && Boolean(localStorage.getItem("manifest-info-dismissed")));

  if (isHidden) return <></>;

  const dismiss = () => {
    localStorage && localStorage.setItem("manifest-info-dismissed", "true");
    setInternalHiddenState(true);
  };

  return (
    <div className={styles.banner}>
      <div className="container">
        <span className={styles.controls}>
          <h1>Noticed that this Fiddle looks different?</h1>
          <a href="#" onClick={() => dismiss()}>
            <Icon type="cross"></Icon>
          </a>
        </span>
        <p>
          Fiddle origins and resources are now defined using <kbd>fastly.toml</kbd> – just like a real Compute service.
          <br />
          Read the <a href="https://www.fastly.com/documentation/reference/compute/fastly-toml/">documentation here</a>. Any questions or
          concerns? Reach out us on <a href="https://community.fastly.com/t/a-new-way-to-configure-compute-fiddles/2694">the community forum thread</a>.
        </p>
      </div>
    </div>
  );
};

export default ManifestBanner;
