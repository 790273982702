import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import ToolTip from "./Tooltip";
import usePrevious from "../lib/hooks/use-previous";

type Props = {
  children: React.ReactNode;
  label?: string;
  onClose?: () => void;
  onOpen?: () => void;
  value: string;
  defaultValue?: string;
};

const PickerControl: FunctionComponent<Props> = ({ label, children, value, defaultValue, onClose, onOpen }: Props) => {
  const [controlVisible, setControlVisible] = useState(false);
  const anchorEl = useRef<HTMLSpanElement>(null);
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (prevValue !== value) {
      setControlVisible(false);
    }
  }, [value, prevValue]);

  const showControl = (): void => {
    if (!controlVisible) {
      requestAnimationFrame(() => setControlVisible(true));
      onOpen && onOpen();
    }
  };

  const hideControl = (): void => {
    if (controlVisible) {
      setControlVisible(false);
      onClose && onClose();
    }
  };
  const cls = "fake-link" + (value !== defaultValue && defaultValue !== undefined ? " non-default" : "");

  return (
    <span>
      {Boolean(label) && <label>{label}:&nbsp;</label>}
      <span className={cls} onClick={showControl} ref={anchorEl}>
        {value}
      </span>
      {anchorEl.current && (
        <ToolTip visible={controlVisible} onHideIntent={hideControl} placement="bottom" trigger="manual" target={anchorEl.current}>
          {children}
        </ToolTip>
      )}
    </span>
  );
};

export default PickerControl;
