import React, { FunctionComponent, useState, useRef, useEffect, KeyboardEvent, useContext } from "react";
import classnames from "classnames";
import type { Fiddle } from "../types";
import AuthStatus from "./AuthStatus";
import Icon from "./Icon";
import { Link } from "raviger";

import styles from "./Title.module.css";
import { JamUserContext } from "./Router";

type Props = Record<string, never> | {
  fiddle: Fiddle;
  isAuthed: boolean;
  changeFiddle: (updater: (newFiddle: Fiddle) => Fiddle) => void;
  onCloneIntent: () => void;
  onLockIntent: () => void;
  onFreezeIntent: () => void;
  onPurgeIntent: () => void;
  onPurgeKeyIntent: (key: string) => void;
};

const Title: FunctionComponent<Props> = (props: Props) => {
  const [draftTitle, setDraftTitle] = useState<string>();
  const [isEditing, setIsEditing] = useState(false);
  const inputEl = useRef<HTMLTextAreaElement>(null);
  const spanEl = useRef<HTMLSpanElement>(null);

  const readOnly = props.fiddle && props.fiddle.isLocked && !props.isAuthed;

  // --------------------------------------------------------------------------
  // Fiddle Jam 2024 (contact @kailan)

  const jamUser = useContext(JamUserContext);

  const urlParams = new URLSearchParams(window.location.search);
  const targetCodeExamplePath = urlParams.get("codeExamplePath") ? atob(urlParams.get("codeExamplePath") || "") : null;
  const [isSubmittingToJam, setIsSubmittingToJam] = useState(false);

  const submitToJam = () => {
    if (isSubmittingToJam) return;

    if (!props.fiddle.title) {
      alert('Give the Fiddle a title before submitting. ("Click to set title" in top left)');
      return;
    }
    if (!props.fiddle.isLocked) {
      alert('Lock the Fiddle before submitting. (Open the menu in the top right)');
      return;
    }

    setIsSubmittingToJam(true);

    (async () => {
      const resp = await fetch('/jam/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          fiddleId: props.fiddle.id,
          codeExamplePath: targetCodeExamplePath,
        })
      });
      if (resp.status === 201) {
        alert('Your code example has been submitted! Thank you for your contribution.');
      } else {
        alert('Failed to submit to jam: ' + resp.statusText);
        setIsSubmittingToJam(false);
      }
    })();
  };
  // --------------------------------------------------------------------------

  useEffect(() => {
    if (spanEl.current && inputEl.current) {
      const boundingBox = spanEl.current.getBoundingClientRect();
      inputEl.current.style.height = boundingBox.height + "px";
      if (isEditing) inputEl.current.focus();
    }
  });

  const detectSpecialKeys = (evt: KeyboardEvent): void => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      saveTitle();
    } else if (evt.key === "Escape") {
      evt.preventDefault();
      setIsEditing(false);
    }
  };

  const saveTitle = (): void => {
    props.changeFiddle && props.changeFiddle((fiddle) => ({ ...fiddle, title: draftTitle }));
    setIsEditing(false);
  };

  const handleClick = (): void => {
    if (readOnly) return;
    setIsEditing(true);
    setDraftTitle((props.fiddle && props.fiddle.title) || "");
  };

  return (
    <header className={`${styles.header} ${jamUser || window.location.pathname === '/jam' ? styles.jam : ''}`}>
      <div className={styles.container}>
        <Link className={styles.logo} href="/" title="Fastly fiddle">
          <Icon type="speedo-white" />
        </Link>
        {props.fiddle ? (
          <>
          <h1
            className={classnames(styles.title, {
              [styles.default]: !(props && props.fiddle.title) && !readOnly,
              [styles.editing]: isEditing,
              [styles.read_only]: readOnly,
            })}
          >
            <span ref={spanEl} onClick={handleClick}>
              {props.fiddle.title || (!readOnly ? "Click to set title" : "Untitled")}
            </span>
            <textarea
              ref={inputEl}
              onChange={(evt): void => setDraftTitle(evt.target.value)}
              onFocus={(): void => {
                if (inputEl.current) inputEl.current.setSelectionRange(0, inputEl.current.value.length);
              }}
              onBlur={saveTitle}
              onKeyDown={detectSpecialKeys}
              placeholder="Click to set title"
              value={draftTitle}
            />
          </h1>
          {
          // --------------------------------------------------------------------------
          // Fiddle Jam 2024 (contact @kailan)
          }
          {jamUser && (
            <Link onClick={submitToJam} className={`${styles.submitToJam} ${isSubmittingToJam ? styles.disabled : ''}`} href={`#`}>
              <span>Submit to jam</span>
            </Link>
          )}
          {
          // --------------------------------------------------------------------------
          }
          <AuthStatus
            fiddle={props.fiddle}
            isAuthed={props.isAuthed}
            onCloneIntent={props.onCloneIntent}
            onLockIntent={props.onLockIntent}
            onFreezeIntent={props.onFreezeIntent}
            onPurgeIntent={props.onPurgeIntent}
            onPurgeKeyIntent={props.onPurgeKeyIntent}
          />
          </>
        ) : (
          <h1 className={styles.title}>Fastly Fiddle</h1>
        )}
      </div>
    </header>
  );
};

export default Title;
