import * as store from "./localStore";
import APIError from "./apiError";
import type { Fiddle, LintStatusType, ApiResponse, InstallData, EcpSdkInfos, AbortablePromise } from "../types";
import { UpdateConflictError } from "../errors";

type Options = {
  data?: Record<string, unknown>;
  password?: string;
};

type ExecutionType = {
  sessionID: string;
  streamHost: string;
  lintStatus: LintStatusType;
};

/**
 * Perform a generic http request to the server, making no promises as to the shape of the
 * data returned.
 */
const http = <T>(method: string, url: string, opts: Options = {}): AbortablePromise<T> => {
  const headers = new Headers({ accept: "application/json" });
  if (opts.data) headers.set("content-type", "application/json");
  if (opts.password) headers.set("Authorization", opts.password);

  const controller = new AbortController();
  const promise = Object.assign(
    new Promise<T>((resolve, reject) => {
      try {
        window
          .fetch(url, {
            signal: controller.signal,
            method,
            headers,
            body: opts.data ? JSON.stringify(opts.data) : undefined,
          })
          .then(
            async (response) => {
              if (!response.ok) {
                const msg = await response.text();
                reject(new APIError(response.status, msg.includes("first byte timeout") ? "Fiddle server not responding" : msg));
              }
              try {
                const responseData = await response.json();
                return resolve(responseData as T);
              } catch (e) {
                return reject(new APIError(response.status, "Unable to parse response as JSON"));
              }
            },
            (e) => {
              if (e.message !== "The user aborted a request.") {
                throw e;
              }
            }
          );
      } catch (e) {
        if (!(e instanceof UpdateConflictError)) throw e;
      }
    }),
    { abort: () => controller.abort() }
  );
  return promise;
};

export const execute = (id: string, cacheID: number): Promise<ExecutionType> => {
  return http("POST", "/fiddle/" + id + "/execute?cacheID=" + cacheID);
};
export const lockFiddle = async (id: string): Promise<string> => {
  const password: string = await http("POST", "/fiddle/" + id + "/lock");
  store.setPassword(id, password);
  return password;
};
export const createFiddle = (fiddle: Fiddle): AbortablePromise<ApiResponse> => http("POST", "/fiddle", { data: fiddle });
export const cloneFiddle = (id: string): Promise<ApiResponse> => http("POST", "/fiddle/" + id + "/clone");
export const getFiddle = (id: string): Promise<ApiResponse> => http("GET", "/fiddle/" + id);
export const getFiddles = (ids: string[]): Promise<Record<string, Fiddle>> => http("GET", "/fiddles?ids=" + ids.join(","));
export const updateFiddle = (fiddle: Fiddle): AbortablePromise<ApiResponse> => {
  if (!fiddle.id) {
    throw new Error("Cannot update fiddle without id");
  }
  return http("PUT", "/fiddle/" + fiddle.id, { data: fiddle, password: store.getPassword(fiddle.id) });
};
export const getInstallData = (id: string): Promise<InstallData> => http("GET", "/fiddle/" + id + "/installdata");
export const purgeKeyInFiddle = (id: string, key: string): Promise<ApiResponse> =>
  http("POST", "/fiddle/" + id + "/purgeKey", { data: { key } });
let _ecpSdkInfos: Promise<EcpSdkInfos> | null = null;
export const getEcpSdkInfos = (): Promise<EcpSdkInfos> => {
  if (_ecpSdkInfos != null) {
    return _ecpSdkInfos;
  }
  _ecpSdkInfos = http("GET", "/ecp-sdk-infos");
  _ecpSdkInfos.catch(() => {
    _ecpSdkInfos = null;
  });
  return _ecpSdkInfos;
};
