import React, { FunctionComponent, useState, useRef, useEffect, useCallback } from "react";
import { Overlay } from "react-overlays";
import classNames from "classnames";
import type * as Popper from "@popperjs/core";

import styles from "./Tooltip.module.css";

const getDwellDelay = (evtType: string): number => (evtType === "mouseover" ? 200 : 500);

type Props = {
  onHideIntent?: () => void;
  contentAttr?: string;
  children?: React.ReactNode;
  placement?: Popper.Placement;
  target: HTMLElement | string;
  trigger: string;
  type?: "validation" | "dark";
  visible?: boolean;
};

const matches = (candidate: EventTarget, target: HTMLElement | string): boolean =>
  (target instanceof HTMLElement && candidate === target) ||
  (typeof target === "string" && candidate instanceof HTMLElement && candidate.matches(target));

const ToolTip: FunctionComponent<Props> = (props: Props) => {
  const currentTargetEl = useRef<HTMLElement | null>(null);
  const [visible, setVisible] = useState<boolean | undefined>();
  const [content, setContent] = useState<string | null>(null);
  const delayTimer = useRef<NodeJS.Timeout | null>(null);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onHideIntent = (): void => {
    if (props.trigger === "hover") setVisible(false);
    if (delayTimer.current) clearTimeout(delayTimer.current);
    props.onHideIntent && props.onHideIntent();
  };

  const handleTrigger = useCallback(
    (evt: MouseEvent): void => {
      let newVisibleState: boolean | undefined;
      if (evt.type === "mouseover") {
        const targetEl = evt.composedPath().find((el) => matches(el, props.target));
        if (targetEl instanceof HTMLElement) {
          newVisibleState = true;
          currentTargetEl.current = targetEl;
          setContent(props.contentAttr ? targetEl.getAttribute(props.contentAttr) : null);
          forceUpdate(); // TODO: Why doesn't setContent above always trigger a re-render?
        }
      } else if (evt.target && (matches(evt.target, props.target) || !evt.composedPath().some((el) => matches(el, props.target)))) {
        // TODO: why doesn't the "datacenter" <OL> element fire a mouseout!?
        newVisibleState = false;
      }
      if (newVisibleState !== undefined) {
        if (delayTimer.current) clearTimeout(delayTimer.current);
        if (newVisibleState !== visible) {
          delayTimer.current = setTimeout(() => {
            setVisible(newVisibleState);
          }, getDwellDelay(evt.type));
        }
      }
    },
    [props.target, props.contentAttr, visible, delayTimer, forceUpdate]
  );

  useEffect(() => {
    const listenRoot = props.target instanceof HTMLElement ? props.target : document.body;
    if (listenRoot && props.trigger === "hover") {
      listenRoot.addEventListener("mouseover", handleTrigger);
      listenRoot.addEventListener("mouseout", handleTrigger);
    }
    return (): void => {
      const listenRoot = props.target instanceof HTMLElement ? props.target : document.body;
      if (listenRoot) {
        listenRoot.removeEventListener("mouseover", handleTrigger);
        listenRoot.removeEventListener("mouseout", handleTrigger);
      }
    };
  }, [props.target, props.trigger, handleTrigger]);

  const isVisible = visible !== undefined ? visible : props.visible || false;
  const target = currentTargetEl.current || props.target;

  if (!(target instanceof HTMLElement)) return null;

  return (
    <Overlay
      show={isVisible}
      onHide={onHideIntent}
      placement={props.placement || "bottom"}
      target={target}
      container={document.body}
      offset={[0,4]}
      flip={true}
      rootClose
    >
      {({ props: tipProps, placement }): JSX.Element => (
        <div {...tipProps} className={classNames(styles.tooltip, styles[placement], props.type && styles[props.type])}>
          <div className={styles.arrow} />
          <div className={styles.inner}>{content || props.children}</div>
        </div>
      )}
    </Overlay>
  );
};

export default ToolTip;
