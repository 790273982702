import React, { FunctionComponent, useEffect } from "react";
import { useRoutes, Routes } from "raviger";
import ReactGA from "react-ga";

import { FIDDLE_LANGUAGES, ROUTING_INTENTS } from "../constants";
import HomePage from "./HomePage";
import FiddleApp from "./FiddleApp";
import { Layout } from "./FiddleUI";
import { Fiddle, FiddleLanguage, JamUser } from "../types";
import LanguageSelector from "./LanguageSelector";
import JamHome from "./JamHome";

type PathParams = {
  id?: string;
  modifier?: string;
  language?: FiddleLanguage;
};

const routes = (ssrData?: Fiddle): Routes<string> => {
  return {
    "/": () => <HomePage />,
    "/new": () => <LanguageSelector computeOnly={false} />,
    "/new/compute": () => <LanguageSelector computeOnly={true} />,
    "/new/:language": ({ language }: PathParams) => {
      if (!language) language = "vcl";
      if (!FIDDLE_LANGUAGES.includes(language)) return <h1>Language not supported</h1>;
      return <FiddleApp newLanguage={language} />;
    },
    "/fiddle/:id": ({ id }: PathParams) => <FiddleApp fiddleID={id} />,
    "/fiddle/:id/:modifier": ({ id, modifier }: PathParams) => (
      <FiddleApp
        fiddleID={id}
        layout={modifier === "embedded" ? Layout.EMBED : Layout.APP}
        intent={ROUTING_INTENTS.find((i) => i === modifier)}
        ssrData={ssrData}
      />
    ),
    "/jam": () => <JamHome />,
  };
};

type Props = {
  ssrData?: Fiddle;
};

// --------------------------------------------------------------------------
// Fiddle Jam 2024 (contact @kailan)

// Value is either user, null for not yet loaded, or false for not logged in
export const JamUserContext = React.createContext<JamUser | null | false>(false);

const urlParams = new URLSearchParams(window.location.search);
const newToken = urlParams.get("jamToken");

if (newToken) {
  document.cookie = `jamToken=${newToken}; path=/`;
  urlParams.delete("jamToken");
  window.location.search = urlParams.toString();
}

// --------------------------------------------------------------------------

const Router: FunctionComponent<Props> = (props?: Props) => {
  const route = useRoutes(routes(props?.ssrData));

  // On route change, record pageview
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, [route]);

  // --------------------------------------------------------------------------
  // Fiddle Jam 2024 (contact @kailan)
  const [jamUser, setJamUser] = React.useState<JamUser | null | false>(null);
  useEffect(() => {
    if (jamUser) return;
    (async () => {
      const resp = await fetch("/jam/profile", { headers: { accept: "application/json" } });
      if (resp.ok) {
        const user = await resp.json();
        setJamUser(user);
      } else {
        setJamUser(false);
      }
    })();
  }, []);
  // --------------------------------------------------------------------------

  return route ? <JamUserContext.Provider value={jamUser}>{route}</JamUserContext.Provider> : <p>Not found</p>;
};

export default Router;
